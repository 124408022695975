import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
// import Image from "../components/image"
import SEO from "../components/seo";
/** @jsx jsx  */
// import { jsx } from "../theme/pragma"
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { useState } from "react";

import shopData from "../../shop-config.json";

// import svgSantasHat from "../images/santas-hat.svg"
import svgCarefree from "../images/icon-carefree.svg";
import svgSolvency from "../images/icon-solvency.svg";
import svgSustainability from "../images/icon-sustainability.svg";
import svgFeatures from "../images/features.svg";

import svgSashimiBg from "../images/sashimi-bg.svg";
import svgSashimiBg2 from "../images/sashimi-bg2.svg";

import gentsonLogo from "../images/logos/gentson.svg";
import hereLogo from "../images/logos/here.svg";
import nikeLogo from "../images/logos/nike.svg";
import oranginaLogo from "../images/logos/orangina.svg";
import samsungLogo from "../images/logos/samsung.svg";
import smartLogo from "../images/logos/smart.svg";
// import spardaLogo from "../images/logos/sparda-bank.svg"
import telekomLogo from "../images/logos/telekom.svg";
// import maxPlanckLogo from "../images/logos/max-planck-institute.png"
import unbabelLogo from "../images/logos/unbabel.svg";

import Grid from "../components/layout/Grid";
import Row from "../components/layout/Row";
import Cell from "../components/layout/Cell";

// const withMargin = WrappedComponent => {
//   return props => {
//     const { mb, mt, ml, mr, m, css: oldCss, ...otherProps } = props || {}
//     console.log(oldCss, otherProps)
//     const newCss = css(
//       oldCss,
//       css`
//         ${typeof mb === "Number" ? `margin-bottom:${mb}px;` : null}
//       `
//     )
//     return <WrappedComponent css={newCss} {...otherProps} />
//   }
// }

const Wrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 560px;
  ${props => props.theme.helpers.mediaquery("lg", "width: 77.5%;")}
  ${props => props.theme.helpers.mediaquery("xl", "max-width: 1240px;")}
`;

const VisuallyHidden = styled.span`
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
`;

const Tick = styled.span`
  font-weight: 700;
`;

const JumboSection = styled.div`
  padding: 11vw 0;
  box-sizing: border-box;
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Headline = styled.h2(
  props => props.theme.styles.text.headline[props.level || 3],
  css`
    margin-bottom: 1em;
  `
);

const Underline = styled.span(
  `/*box-shadow: inset 0 -0.37em 0px rgba(255, 101, 101, .38);*/`
);

const Text = styled.p(
  props => props.theme.styles.text.default[props.level || 0]
);

const BenefitList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const BenefitItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: -20px;
  padding: 20px 0;
  &:after {
    content: "";
    position: absolute;
    top: calc(100% - 10px);
    left: 20px;
    width: calc(100% - 40px);
    border-top: rgba(94, 94, 94, 0.5) 1px solid;
  }

  &:nth-of-type(1) {
    &:before {
      content: "";
      position: absolute;
      top: 20px;
      left: 20px;
      width: calc(100% - 40px);
      border-top: rgba(94, 94, 94, 0.5) 1px solid;
    }
  }

  @media (min-width: 640px) {
    flex-direction: row;
  }

  > * {
    padding: 20px;
  }
`;

const Button = styled.button(
  props => props.theme.styles.button[props.variant || "default"]
);

const Badge = styled.span(
  css`
    display: inline-block;
    color: #fff;
    font-size: 11px;
    line-height: 12px;
    font-weight: 700;
    letter-spacing: 0.09375em;
    text-transform: uppercase;
    border-radius: 10px;
    background-color: rgba(255, 96, 96, 0.7);
    padding: 4px 12px;
  `,
  props => {
    return props.shy
      ? {
          background: "none",
          paddingLeft: 0,
          paddingRight: 0,
          color: "rgba(94, 94, 94, 0.3)",
        }
      : {};
  }
);

const TrPrice = styled.tr`
  > th,
  td {
    padding: 14px 0;
    border-top: solid 1px rgba(94, 94, 94, 0.3);
  }
`;

const IndexPage = props => {
  // const theme = useTheme()
  const [isBenefitExpanded, setIsBenefitExpanded] = useState(false);

  const pricing = shopData.items;

  return (
    <Layout>
      <SEO
        title="Professionelle, schnelle, günstige Websites. Google SEO, Performance, Conversion."
        lang="de"
      />
      <JumboSection
        css={theme => `
          background: ${theme.tokens.color.backgroundSecondary} url(${svgSashimiBg}) center top no-repeat;
          background-size: cover;
          margin-bottom: 12vw;
        `}
      >
        <Wrapper
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
          `}
        >
          <div
            css={css`
              position: relative;
              width: 120%;
              top: 0;
              left: -14%;
              margin: 0 auto 7vw;

              @media (min-width: 640px) {
                left: -12%;
                margin-top: -7vw;
              }
              @media (min-width: 768px) {
                left: -3%;
                width: 90%;
              }
              @media (min-width: 1024px) {
                left: auto;
                margin: 0 0 0 640px;
                width: calc(100% - 640px);
                order: 3;
                height: 2em;
              }
            `}
          >
            <div
              css={css`
                perspective: 400px;
                perspective-origin: center;
                @media (min-width: 1024px) {
                  position: absolute;
                  right: -70px;
                  bottom: -39px;
                  width: calc(100% + 300px);
                }
              `}
            >
              <Img fluid={props.data.macbook.childImageSharp.fluid} alt="" />
            </div>
          </div>
          <Headline
            as="h1"
            level={1}
            css={theme => css`
              margin-bottom: 0.5em;
              font-size: 38px;
              line-height: 48px;
              ${theme.helpers.mediaquery(
                "lg",
                `
                font-size: 60px;
                line-height: 64px;
                `
              )}
              ${theme.helpers.mediaquery(
                "xxl",
                `
                font-size: 80px;
                line-height: 88px;
                max-width: 950px;
                order: 1;`
              )}
            `}
          >
            <Underline>
              Geschäftswachstum durch hoch&shy;kon&shy;ver&shy;tierende Websites
            </Underline>
          </Headline>
          <Text
            level={2}
            css={css`
              position: relative;
              z-index: 99;
              @media (min-width: 1024px) {
                max-width: 500px;
                order: 2;
              }
            `}
            as="ul"
          >
            <li>
              Google liebt unseren Code (95 % durchschnittlicher Performance
              Score).
            </li>
            <li>Holt neue Kunden zu dir.</li>
            <li>Eine ganze Digitalabteilung für minimales Budget.</li>
          </Text>
          <div
            css={css`
              padding-top: 3em;
              @media (min-width: 1024px) {
                order: 4;
              }
            `}
          >
            <div
              css={css`
                display: inline-block;
                position: relative;
                z-index: 2;
              `}
            >
              <Badge
                as="div"
                css={css`
                  position: absolute;
                  right: -3.2em;
                  bottom: 0.3em;
                  min-width: 120px;
                  min-height: 120px;
                  text-align: center;
                  border-radius: 60px;
                  box-sizing: border-box;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #ff4242;
                  transform: rotate(-10deg);
                `}
              >
                {/* <img
              src={svgSantasHat}
              alt=""
              css={css`
                display: block;
                position: absolute;
                left: -5%;
                bottom: 67%;
                width: 110%;
                height: auto;
                transform: translate(23%, 0) rotate(14deg);
              `}
            /> */}
                <div>
                  {/* <div
                css={css`
                  margin: 0.6em 0;
                  position: absolute;
                  left: -30px;
                  top: 5px;
                  width: calc(100% + 60px);
                  text-align: center;
                  color: black;
                  font-size: 1.1em;
                  transform: translate(9%, -35%) rotate(12deg);
                `}
              >
                X-MAS SPECIAL
              </div> */}
                  <div
                    css={css`
                      padding-top: 0.8em;
                    `}
                  >
                    Jetzt bis zu
                  </div>
                  <div
                    css={css`
                      font-size: 2.7em;
                      line-height: 1.2;
                    `}
                  >
                    –20{" "}
                    <span
                      css={css`
                        position: relative;
                        top: 0;
                        left: -0.23em;
                      `}
                    >
                      %
                    </span>
                  </div>
                  <div
                    css={css`
                      padding-left: 0.3em;
                    `}
                  >
                    Rabatt*
                  </div>
                </div>
              </Badge>
            </div>
            <Button
              as={Link}
              to="/bestellen"
              css={css`
                margin-right: var(--space-4);
              `}
            >
              Jetzt Website bestellen
            </Button>
            <Button
              as={Link}
              to="/"
              variant="secondary"
              css={css`
                &:hover {
                  background-color: var(--color-background);
                }
              `}
            >
              Mehr erfahrern
            </Button>
            <Text>
              * Nur gültig für die ersten 10 Kunden und bis zum 06.12.2020
            </Text>
          </div>

          <Text
            css={css`
              order: 6;
              text-align: center;
              margin-bottom: -6vw;
            `}
          >
            Marken, mit denen wir bereits gearbeitet haben.
          </Text>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              order: 5;
              margin-top: 6vw;
            `}
          >
            {[
              nikeLogo,
              smartLogo,
              telekomLogo,
              unbabelLogo,
              gentsonLogo,
              oranginaLogo,
              hereLogo,
              samsungLogo,
            ].map((v, i, a) => (
              <img
                src={v}
                key={v}
                css={theme => css`
                  flex-grow: unset;
                  display: block;
                  width: ${100 / (a.length / 2 + 0.75)}%;
                  height: auto;

                  ${theme.helpers.mediaquery(
                    "xl",
                    `
                      width: ${100 / (a.length + 1)}%;
                  `
                  )}
                `}
                alt="NIKE"
              />
            ))}
          </div>
        </Wrapper>
      </JumboSection>
      <section
        css={css`
          padding-bottom: 12vw;
        `}
      >
        <Wrapper>
          <Headline level={1}>
            <Underline>Benefits</Underline>
          </Headline>
          <Grid>
            <Row>
              {[
                {
                  svg: svgCarefree,
                  headline: "Sorgenfreiheit",
                  text:
                    "Wir sorgen für einen schnellen Launch und betreuen die Website für die Dauer des Vertrags. Machen Sie Ihren Kopf frei von Sorgen über Server-Wartung, Sicherheits-Updates, Wartungskosten. Suchmaschinen&shy;freundlicher Code sowie responsives und barrierearmes Design gibt es Out-of-the-box!",
                },
                {
                  svg: svgSolvency,
                  headline: "Liquidität",
                  text:
                    "Wir sorgen für einen schnellen Launch und betreuen die Website für die Dauer des Vertrags. Machen Sie Ihren Kopf frei von Sorgen über Server-Wartung, Sicherheits-Updates, Wartungskosten. Suchmaschinenfreundlicher Code sowie responsives und barrierearmes Design gibt es Out-of-the-box!",
                },
                {
                  svg: svgSustainability,
                  headline: "Nachhaltigkeit",
                  text:
                    "Während der Dauer des Vertrags bleibt die Website für alle gängigen modernen Browser lauffähig! Ebenso wächst Ihre Website mit Ihren Bedürfnissen. Durch unser modulares System, können wir Ihre Website jederzeit erweitern.",
                },
              ].map(d => (
                <Cell span={{ xl: 6 / 12, xxl: 4 / 12 }} key={d.headline}>
                  <img
                    src={d.svg}
                    css={theme => css`
                      display: block;
                      width: 30%;
                      height: auto;
                      margin: 8% auto 12%;
                      max-width: 120px;

                      ${theme.helpers.mediaquery("xl", `max-width: 160px;`)}
                    `}
                  />
                  <Text
                    as="h3"
                    level={1}
                    css={css`
                      font-weight: 700;
                      margin-bottom: 0.5em;
                    `}
                  >
                    {d.headline}
                  </Text>
                  <Text dangerouslySetInnerHTML={{ __html: d.text }} />
                </Cell>
              ))}
            </Row>
          </Grid>
        </Wrapper>
      </section>
      <section
        css={theme => css`
          padding: 7.5vw 0;
          background: ${theme.tokens.color.backgroundSecondary}
            url(${svgSashimiBg}) center top no-repeat;
          background-size: cover;
        `}
      >
        <Wrapper>
          <Headline level={1}>
            <Underline>Features</Underline>
          </Headline>
          <Grid>
            <Row>
              <Cell
                span={{ lg: 1 / 3 }}
                css={css`
                  position: relative;
                `}
              >
                <img
                  src={svgFeatures}
                  alt=""
                  css={theme => css`
                    display: block;
                    margin: -35px auto;
                    max-width: 370px;

                    ${theme.helpers.mediaquery(
                      "lg",
                      `
                      margin: 0;
                      max-width: none;
                      width: 180%;
                      position: absolute;
                      top: 0;
                      right: -15%;`
                    )}
                  `}
                />
              </Cell>
              <Cell span={{ lg: 2 / 3 }}>
                <Row>
                  {[
                    {
                      headline: "Quick Launch",
                      text:
                        "Vom Beginn bis zum Launch in ein bis zwei Wochen. Schneller Wettbewerbs-vorteil für Ihre Geschäftsidee.",
                    },
                    {
                      headline: "SEO ready",
                      text:
                        "Der Quellcode ist durchweg suchmaschinenfreundlich und nebenbei auch barrierearm. Beste Voraussetzungen für gutes Ranking auf Google & Co.",
                    },
                    {
                      headline: "Unbegrenzte Content-Pflege",
                      text:
                        "Wir sind Ihre digitale Abteilung. Während der Betreuung setzen wir jede Inhaltsänderung für Sie um.",
                    },
                    {
                      headline: "SSL Zertifikat",
                      text:
                        "Datenaustausch findet nur über ein Sicherheitszertifikat statt. Und das inklusive!",
                    },
                    {
                      headline: "Hochperformant",
                      text:
                        "Der Quellcode ist durchweg suchmaschinenfreundlich und nebenbei auch barrierearm. Beste Voraussetzungen für gutes Ranking.",
                    },
                    {
                      headline: "Domain & Hosting",
                      text:
                        "Beides ist inklusive. So sparen Sie sich das aufwendige Konfigurieren von Domain und Server.",
                    },
                  ].map(d => (
                    <Cell span={{ xxl: 6 / 12, x3l: 4 / 12 }} key={d.headline}>
                      <Text
                        as="h3"
                        level={1}
                        css={css`
                          font-weight: 700;
                          margin-bottom: 0.5em;
                        `}
                      >
                        {d.headline}
                      </Text>
                      <Text>{d.text}</Text>
                    </Cell>
                  ))}
                </Row>
              </Cell>
            </Row>
          </Grid>
        </Wrapper>
      </section>
      <section
        css={css`
          padding-top: 12vw;
          padding-bottom: 12vw;
        `}
      >
        <Wrapper>
          <Headline level={1}>
            <Underline>Preise</Underline>
          </Headline>
          <Text
            level={2}
            css={css`
              margin-bottom: 3em;
            `}
          >
            Erhalten Sie Ihre professionelle Website zu einem unschlagbar
            günstigen Preis. Die Preisgestaltung erlaubt Ihrem Unternehmen
            gerade in schwierigen Zeiten höchste Liquidität. Mit einer stark
            reduzierten einmaligen Zahlung ist für jedes Unternehmen der Weg zur
            eigenen Website frei.
          </Text>
          <div
            css={css`
              overflow-x: auto;
            `}
          >
            <table
              role="table"
              css={css`
                width: 100%;
                min-width: 760px;
                border-spacing: 0;
                border-collapse: separate;
                th,
                td {
                  text-align: left;
                  font-weight: inherit;
                }
              `}
            >
              <thead role="rowgroup">
                <tr role="row">
                  <td></td>
                  <th role="columnheader">
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: flex-start;
                      `}
                    >
                      <Headline
                        as="h3"
                        css={css`
                          margin-bottom: 0.2em;
                        `}
                      >
                        Starter
                      </Headline>
                      <Badge
                        shy
                        css={css`
                          margin-bottom: 1em;
                        `}
                      >
                        Günstigster Einstieg
                      </Badge>
                    </div>
                  </th>
                  <th role="columnheader">
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: flex-start;
                      `}
                    >
                      <Headline
                        as="h3"
                        css={css`
                          margin-bottom: 0.2em;
                        `}
                      >
                        Experte
                      </Headline>
                      <Badge
                        css={css`
                          margin-bottom: 1em;
                        `}
                      >
                        Meistgewählt
                      </Badge>
                    </div>
                  </th>
                  <th role="columnheader">
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: flex-start;
                      `}
                    >
                      <Headline
                        as="h3"
                        css={css`
                          margin-bottom: 0.2em;
                        `}
                      >
                        Dynamic
                      </Headline>
                      <Badge
                        shy
                        css={css`
                          margin-bottom: 1em;
                        `}
                      >
                        In Vorbereitung
                      </Badge>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr role="row">
                  <th role="rowheader">
                    <VisuallyHidden>Preis</VisuallyHidden>
                  </th>
                  <td
                    css={css`
                      vertical-align: bottom;
                    `}
                  >
                    <Text level={2}>{pricing.starter.monthly},– €*</Text>
                    <Text
                      css={css`
                        font-size: 11px;
                        margin: -1em 0 1.8em;
                        color: rgba(0, 0, 0, 0.5);
                      `}
                    >
                      monatlich{" "}
                      <abbr title="zuzüglich Mehrwertsteuer">zzgl. MwSt.</abbr>
                    </Text>
                    <Button
                      as={Link}
                      to="/bestellen"
                      variant="secondary"
                      css={css`
                        font-size: 14px;
                        line-height: 20px;
                        padding: 7px 16px;
                        border-radius: 5px;
                        margin-bottom: 1.4em;
                      `}
                    >
                      Website bestellen
                    </Button>
                  </td>
                  <td
                    css={css`
                      vertical-align: bottom;
                    `}
                  >
                    <Text level={2}>{pricing.expert.monthly},– €*</Text>
                    <Text
                      css={css`
                        font-size: 11px;
                        margin: -1em 0 1.8em;
                        color: rgba(0, 0, 0, 0.5);
                      `}
                    >
                      monatlich{" "}
                      <abbr title="zuzüglich Mehrwertsteuer">zzgl. MwSt.</abbr>
                    </Text>
                    <Button
                      as={Link}
                      to="/bestellen"
                      css={css`
                        font-size: 14px;
                        line-height: 20px;
                        padding: 7px 16px;
                        border-radius: 5px;
                        margin-bottom: 1.4em;
                      `}
                    >
                      Website bestellen
                    </Button>
                  </td>
                  <td
                    css={css`
                      vertical-align: bottom;
                    `}
                  >
                    <Button
                      variant="secondary"
                      css={css`
                        font-size: 14px;
                        line-height: 20px;
                        padding: 7px 16px;
                        border-radius: 5px;
                        margin-bottom: 1.4em;
                      `}
                    >
                      Infos anfordern
                    </Button>
                  </td>
                </tr>
                <TrPrice role="row">
                  <th role="rowheader">Umfang der Seite</th>
                  <td>Bis zu 5 Seiten</td>
                  <td>Bis zu 10 Seiten</td>
                  <td>∞</td>
                </TrPrice>
                <TrPrice role="row">
                  <th role="rowheader">Gestaltung und Seitenaufbau</th>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                </TrPrice>
                <TrPrice role="row">
                  <th role="rowheader">Domain & Hosting inklusive</th>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                </TrPrice>
                <TrPrice role="row">
                  <th role="rowheader">SSL Zertifikat inklusive</th>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                </TrPrice>
                <TrPrice role="row">
                  <th role="rowheader">Technische Wartung inklusive</th>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                </TrPrice>
                <TrPrice role="row">
                  <th role="rowheader">Pflege des Inhalts</th>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                  <td>
                    <Tick>✓</Tick>
                    <VisuallyHidden>inklusive</VisuallyHidden>
                  </td>
                </TrPrice>
                {/* <TrPrice role="row">
                  <th role="rowheader">Einmalige Einrichtungskosten</th>
                  <td>
                    <del>
                      {pricing.starter.initial},— €{" "}
                    </del>
                    <Text level={1}>
                      {pricing.starter.initialDiscounted},— €{" "}
                      <abbr
                        title="zuzüglich Mehrwertsteuer"
                        css={css`
                          display: block;
                          margin-top: -1.2em;
                          font-size: 11px;
                        `}
                      >
                        zzgl. MwSt.
                      </abbr>
                    </Text>
                    <div
                      css={css`
                        color: #ff4242;
                      `}
                    >
                      16&nbsp;%&nbsp;Rabatt
                    </div>
                  </td>
                  <td>
                    <del>
                      {pricing.expert.initial},— €{" "}
                    </del>
                    <Text level={1}>
                      {pricing.expert.initialDiscounted},— €{" "}
                      <abbr
                        title="zuzüglich Mehrwertsteuer"
                        css={css`
                          display: block;
                          margin-top: -1.2em;
                          font-size: 11px;
                        `}
                      >
                        zzgl. MwSt.
                      </abbr>
                    </Text>
                    <div
                      css={css`
                        color: #ff4242;
                      `}
                    >
                      20&nbsp;%&nbsp;Rabatt
                    </div>
                  </td>
                  <td>
                    <VisuallyHidden>ausstehend</VisuallyHidden>
                  </td>
                </TrPrice> */}
              </tbody>
            </table>
          </div>
          <div
            css={css`
              font-size: 11px;
              color: rgba(0, 0, 0, 0.5);
            `}
          >
            * Mindestvertragslaufzeit: 24 Monate
          </div>
        </Wrapper>
      </section>
      <section
        css={css`
          padding: 7.5vw 0;
          background-color: #f2f2f2;
        `}
      >
        <Wrapper>Testimonials</Wrapper>
        <Grid>
          <Row>
            <Cell>
              Meine Website hat 100 von 100 Punkten bei Googles PageSpeed
              Insights. Ich bin einfach happy.
            </Cell>
          </Row>
        </Grid>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    macbook: file(relativePath: { eq: "macbook.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 862, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
